<script setup lang="ts">
const { $flavorManager, $geolocation } = useNuxtApp();

// We want to prevent showing, but not hide it later, so never turn it off.
const hasGeoBeenInitialized = ref($geolocation.initialized);
if (!hasGeoBeenInitialized.value) {
    const stopGeoWatcher = watch(() => $geolocation.initialized, (initialized) => {
        if (initialized) {
            hasGeoBeenInitialized.value = true;
            stopGeoWatcher();
        }
    });
}

const logo = $flavorManager.splashLogo();
</script>

<template>
    <div id="auth">
        <div v-if="hasGeoBeenInitialized" class="splash-container">
            <SplashAnimationGfx v-if="$flavorManager.flavor === 'gfx'" :logo="logo" />
            <SplashAnimationProtos v-else-if="$flavorManager.flavor === 'protos'" :logo="logo" />
            <SplashAnimationSecuritime v-else-if="$flavorManager.flavor === 'securitime'" :logo="logo" />
            <SplashAnimationProtosConnect v-else-if="$flavorManager.flavor === 'protos-connect'" :logo="logo" />
            <div class="splash-panel-container">
                <main class="splash-panel">
                    <slot></slot>
                </main>
            </div>
        </div>
    </div>
</template>
